<template>
  <div>
    <div v-if="loading" style="min-height: 200px;" class="d-flex flex-row align-center">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else class="information">
      <button
        v-if="isManager"
        class="contact-add_btn" @click="contactCreation = !contactCreation"
      >
        Добавить контакт
      </button>
      <InfoContact
        v-for="(item, j) in contactsArray" :key="j"
        @getAll="getAll"
        :value.sync="item"
        :arrayLength="contactsArray.length"
      />
      <v-dialog
        v-model="contactCreation"
        persistent
        max-width="1250px"
      >
        <v-card class="dialog">
          <img @click="contactCreation = false" class="dialog__exit" src="@/assets/icons/dialog-exit.png" alt="exit">
          <h2 class="dialog__header">Добавление контакта</h2>
          <div class="dialog__line"></div>
          <div class="dialog__div">
            <label class="dialog__label">Заголовок контакта</label>
            <v-text-field
              v-model="contactItem.type"
              required
            ></v-text-field>
            <div class="dialog__line"></div>
            <div v-for="(item, x) in contactItem.contact" :key="x">
              <label class="dialog__label">Фамилия Имя Отчество</label>
              <v-text-field
                v-model="item.fio"
                required
              ></v-text-field>
              <label class="dialog__label">Телефон</label>
              <v-text-field
                v-model="item.phoneNumber"
                required
              ></v-text-field>
              <label class="dialog__label">Электронная почта</label>
              <v-text-field
                v-model="item.email"
                required
              ></v-text-field>
              <div v-if="x + 1 !== contactItem.contact.length" class="dialog__line"></div>
            </div>
            <img
              src="@/assets/icons/add-file-round-blue.svg" alt="add-file"
              class="dialog__contacts-add-input" @click="addContactInput"
            >
          </div>
          <div class="dialog__line"></div>
          <v-card-actions>
            <button
              class="dialog-btn" @click="addContact">
              Сохранить
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import InfoContact from "@/layouts/infoContacts/InfoContact";
import axios from "axios";

export default {
  name: "Info",
  components: {InfoContact},
  computed: {
    ...mapGetters('keycloak',
      {
        getAuthData: 'getAuthData',
        isManager: 'isManager',
      }),
  },
  data() {
    return {
      contactCreation: false,
      loading: false,
      contactsArray: [],
      contactItem: {
        type: '',
        contact: [
          {
            fio: '',
            phoneNumber: '',
            email: '',
          }
        ]
      }
    }
  },
  created() {
    this.getAll();
  },
  methods: {
    async getAll() {
      this.loading = true;
      try {
        const res = await axios.get(`/api/cms/minObrContacts/get`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.contactsArray = res.data;
          console.log('getAll успех');
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    
    async addContact() {
      this.loading = true;
      const array = [];
      for (let k = 0; k < this.contactItem.contact.length; k++) {
        array.push({
          "fio": this.contactItem.contact[k].fio,
          "phoneNumber": this.contactItem.contact[k].phoneNumber,
          "email": this.contactItem.contact[k].email,
        })
      }
      try {
        const res = await axios.post(`/api/cms/minObrContacts/set`, {
          "type": this.contactItem.type,
          "minObrContactInfoList": array
        }, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          await this.getAll();
          this.contactCreation = false;
          this.contactItem = {
            type: '',
            contact: [
              {
                fio: '',
                phoneNumber: '',
                email: '',
              }
            ]
          }
          console.log('addContact успех');
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    
    addContactInput() {
      this.contactItem.contact.push({
        fio: '',
        phoneNumber: '',
        email: '',
      })
    }
  }
}
</script>

<style scoped>

</style>