<template>
  <div>
    <div class="info__div">
      <p
        :style="isManager ? 'max-width: 950px' : ''"
        class="info__div-header">
        {{ value.type }}
      </p>
      <v-btn
        v-if="isManager"
        :disabled="value.sortOrder === 1" style="right: 125px" @click="moveInfoCard(value.id, 'DOWN')"
        class="infoCard-move-btn">
        &#8593;
      </v-btn>
      <v-btn
        v-if="isManager"
        :disabled="value.sortOrder === arrayLength" @click="moveInfoCard(value.id, 'UP')"
        class="infoCard-move-btn" style="right: 55px">
        &#8595;
      </v-btn>
      <img
        v-if="isManager"
        src="@/assets/icons/edit-btn.svg" alt="edit"
        class="contact-edit" @click="contactEdit = !contactEdit"
      >
      <div class="info__line"></div>
      <div class="info__person-container">
        <div v-for="(item, j) in value.minObrContactInfoList" :key="j" class="info__person">
          <p class="info__person-name">{{ item.fio }}</p>
          <p class="info__person-telephone">Телефон: {{ item.phoneNumber }}</p>
          <p class="info__person-email">Электронная почта: <span>{{ item.email }}</span></p>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="contactEdit"
      persistent
      max-width="1250px"
    >
      <v-card class="dialog">
        <img @click="contactEdit = false" class="dialog__exit" src="@/assets/icons/dialog-exit.png" alt="exit">
        <h2 class="dialog__header">Редактирование контакта</h2>
        <div class="dialog__line"></div>
        <div class="dialog__div">
          <label class="dialog__label">Заголовок контакта</label>
          <v-text-field
            v-model="value.type"
            required
          ></v-text-field>
          <div class="dialog__line" style="margin-bottom: 10px;"></div>
          <div style="position: relative" v-for="(contact, x) in value.minObrContactInfoList" :key="x">
            <v-btn
              :disabled="contact.sortOrder === 1" style="right: 125px" @click="moveInfoContact(contact.id, 'DOWN')"
              class="infoContact-move-btn">
              &#8593;
            </v-btn>
            <v-btn
              :disabled="contact.sortOrder === value.minObrContactInfoList.length"
              @click="moveInfoContact(contact.id, 'UP')"
              class="infoContact-move-btn" style="right: 55px">
              &#8595;
            </v-btn>
            <img class="dialog__contact-delete" src="@/assets/icons/close-red-round.svg" alt="delete"
                 @click="deleteContactRow(contact.id)">
            <label class="dialog__label">Фамилия Имя Отчество</label>
            <v-text-field
              v-model="contact.fio"
              required
            ></v-text-field>
            <label class="dialog__label">Телефон</label>
            <v-text-field
              v-model="contact.phoneNumber"
              required
            ></v-text-field>
            <label class="dialog__label">Электронная почта</label>
            <v-text-field
              v-model="contact.email"
              required
            ></v-text-field>
            <div v-if="x + 1 !== value.minObrContactInfoList.length" style="margin-bottom: 10px;"
                 class="dialog__line"></div>
          </div>
          <img
            src="@/assets/icons/add-file-round-blue.svg" alt="add-file"
            class="dialog__contacts-add-input" @click="addContactInput"
          >
        </div>
        <div class="dialog__line"></div>
        <v-card-actions>
          <button
            class="dialog-btn" @click="editContact">
            Сохранить
          </button>
          <button
            class="dialog-btn dialog-btn_delete" @click="deleteContact(value.id)">
            Удалить
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "InfoContact",
  props: {
    value: {
      type: [Object, Array],
      default: function () {
        return {
          contactForEdit: {}
        }
      },
    },
    arrayLength: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      isManager: 'isManager',
    }),
  },
  data() {
    return {
      contactEdit: false,
    }
  },
  methods: {
    async editContact() {
      this.loading = true;
      const array = [];
      for (let k = 0; k < this.value.minObrContactInfoList.length; k++) {
        array.push({
          "id": this.value.minObrContactInfoList[k].id,
          "fio": this.value.minObrContactInfoList[k].fio,
          "phoneNumber": this.value.minObrContactInfoList[k].phoneNumber,
          "email": this.value.minObrContactInfoList[k].email,
        })
      }
      try {
        const res = await axios.post(`/api/cms/minObrContacts/set`, {
          "id": this.value.id,
          "type": this.value.type,
          "minObrContactInfoList": array
        }, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.$emit('getAll');
          this.contactEdit = false;
          console.log('editContact успех');
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    async deleteContactRow(id) {
      this.value.minObrContactInfoList = this.value.minObrContactInfoList.filter(el => el.id !== id);
      console.log(id);
      if (id) {
        try {
          const res = await axios.delete(`/api/cms/minObrContacts/contactInfo/delete?rowId=${id}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
          if (res.status === 200 && res.data) {
            this.$emit('getAll');
            console.log('deleteContactRow успех');
          }
        } catch (e) {
          console.log(e);
        }
      }
    },

    async deleteContact(id) {
      try {
        const res = await axios.delete(`/api/cms/minObrContacts/delete?rowId=${id}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.$emit('getAll');
          console.log('deleteContact успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    addContactInput() {
      this.value.minObrContactInfoList.push({
        fio: '',
        phoneNumber: '',
        email: '',
      })
    },

    async moveInfoCard(id, arg) {
      try {
        const res = await axios.put(`/api/cms/minObrContacts/move?rowId=${id}&moveOrder=${arg}`, '', {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.$emit('getAll');
          console.log('moveInfoCard успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async moveInfoContact(id, arg) {
      try {
        const res = await axios.put(`/api/cms/minObrContacts/contactInfo/move?rowId=${id}&moveOrder=${arg}`, '', {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.value.minObrContactInfoList = res.data;
          console.log('moveInfoContact успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.infoCard-move-btn {
  position: absolute;
  height: 26px !important;
  padding: 0 8px !important;
  margin-left: 15px !important;
  top: 15px;
  cursor: url(../../assets/icons/cursor.svg), auto;
}

.infoContact-move-btn {
  position: absolute;
  height: 26px !important;
  padding: 0 8px !important;
  margin-left: 15px !important;
  top: -5px;
  cursor: url(../../assets/icons/cursor.svg), auto;
}
</style>